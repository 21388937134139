import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import ProfileInsurance from './Insurance';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';

const transformIdentity = identity => ({
  identityDocumentPhotos: [
    {
      delete: !identity.frontPhoto,
      page: 1,
      identityDocumentPhoto: identity.frontPhoto,
    },
    {
      delete: !identity.backPhoto,
      page: 2,
      identityDocumentPhoto: identity.backPhoto,
    },
  ],
});

const transformInsurance = insurance =>
  insurance.map(item => {
    const payload = {
      insuranceId: Number.isInteger(item.id) ? null : item.id,
      insurancePhotos: [
        {
          delete: !item.frontPhoto,
          page: 1,
          insurancePhoto: item.frontPhoto,
        },
        {
          delete: !item.backPhoto,
          page: 2,
          insurancePhoto: item.backPhoto,
        },
      ],
      insuranceProvider: item.providerCustomName || item.providerName,
      insurancePlan: item.planCustomName || item.planName,
      insuranceMemberId: item.memberId,
      insuranceGroupId: item.groupNumber,
      insuranceType: item.type,
      relationship: item.relationshipType,
      policyHolderFirstName: item.policyHolderFirstName,
      policyHolderMiddleName: item.policyHolderMiddleName,
      policyHolderLastName: item.policyHolderLastName,
      policyHolderGender: item.policyHolderGender,
      effectiveFrom: item.effectiveFrom,
      policyHolderDateOfBirth: item.policyHolderDateOfBirth,
      delete: item.delete,
    };

    return payload;
  });

const enableUpdateButton = complete =>
  store.dispatch.button.enableButton({
    btn: 'profileSettingsUpdate',
    complete,
  });

const customFields = { ProfileInsurance };

export const Component = React.memo(({ submit, client, schema, formData, uiSchema }) => {
  const [form, setForm] = useState({ formData });
  const formContext = useMemo(() => ({ errors: [] }), []);
  const isFormValid = (!Array.isArray(form.errors) || form.errors.length < 1) && formContext.errors.length < 1;
const bit = form.formData.identity.PICTURE_INSURANCE_CARD;
const bit2 = form.formData.identity.required;
  store.dispatch.settings.PICTURE_INSURANCE_CARD(bit)
  store.dispatch.settings.Document_Forntphoto(bit2)


  let isPhotoRequired ;
  if(form.formData.identity.required=== "true" || form.formData.identity.required  === true){
    if(form.formData.identity.frontPhoto === '' || form.formData.identity.frontPhoto === undefined){
      isPhotoRequired =false;
    }else{
      isPhotoRequired = true
    }
   }else{
    isPhotoRequired  = true
  }
  useEffect(() => {
    store.dispatch.loader.hide();
  }, []);

  const handleSubmit = async () => {
    enableUpdateButton(false);

    try {
      const { data } = await client.mutate({
        mutation: Mutations.documents,
        variables: {
          input: {
            insurances: transformInsurance(form.formData.insurance),
            identityDocument: transformIdentity(form.formData.identity),
            patientId: store.getState().settings.patient_Id,
          },
        },
      });

      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.open(data[Object.keys(data)[0]]);
      store.dispatch.settings.refetch();
    } catch (error) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.error(error?.message);
      enableUpdateButton(true);
    }

    store.dispatch.loader.hide();
  };

  const handleChange = () => enableUpdateButton(form.edit && isFormValid && isPhotoRequired);

  submit ? handleSubmit() : handleChange();

  return (
    <Form
      customFields={customFields}
      formContext={formContext}
      formData={form.formData}
      schema={schema}
      uiSchema={uiSchema}
      onChange={setForm}
    />
  );
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);
