import React, { useEffect, useState } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { compose, get, isEmpty } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { dashboardContext as DashboardContext } from '../../../contexts';
import { GetDashboard, GetVideoVisitUrl, GetPatientStatement } from '../../../graphql/queries';
import { CancelAppointment } from '../../../graphql/mutations';
import { store } from '../../../models';
import { SchemaParser } from '../../services';
import styles from './dashboard.module.scss';
import mix from '../../services/mixpanel';
import { INCOMPLETE_REGISTRATION_ROUTE } from '../../../constants/Routes';

export const DashboardContainer = props => {
  const [dashboardLayout, setDashboardLayout] = useState([]);
  const [dashboard2faModalSchema, setDashboard2faModalSchema] = useState([]);
  const loadPatientStatement = async param => {
    // store.dispatch.payments.clearPayments();
    store.dispatch.loader.activateLoader({ loading: true });
    const {
      data: { getPatientStatement: { base64Str = String() } = {} },
    } = await props.client.query({
      query: GetPatientStatement,
      variables: { statementId: param.toString() },
    });
    const byteCharacters = atob(base64Str);
    const byteNumbers = new Array(byteCharacters.length);
    [...byteCharacters].forEach((value, i) => {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    });
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(file);
    } else {
      const url = URL.createObjectURL(file);
      window.open(url);
    }
    return store.dispatch.loader.activateLoader({ loading: false });
  };
  async function fetchDashboard() {
    // let isSubscribed = true;
    try {
      store.dispatch.loader.activateLoader({ loading: true });
      // if (isSubscribed){
      const {
        data: {
          getDashboard,
          getDashboard: { missingRegistrationFields },
        },
      } = await props.client.query({
        query: GetDashboard,
      });
      //
      //
      // Remove usestate and save schema in redux dashboard.fetchStart //
      //
      //
      store.dispatch.appointments.setMissingRegistrationFields({ missingRegistrationFields });
      setDashboardLayout(getDashboard.dashboardScreen);
      store.dispatch.loader.activateLoader({ loading: false });
      const telehealthBtn = document.getElementById('telehealthBtn');
      if (
        telehealthBtn &&
        telehealthBtn.innerHTML.trim() === 'Join Telehealth Session' &&
        telehealthBtn.className.includes('disabled')
      ) {
        if (
          moment(moment(document.getElementById('nextAppointmentTimeValue').innerText, 'h:mm A')).diff(
            moment(),
            'minutes',
          ) < 30
        )
          telehealthBtn.innerHTML += '<span id="tooltip">Please check-in the appointment to enable this button</span>';
        else
          telehealthBtn.innerHTML +=
            '<span id="tooltip">This button will be enabled 30 minutes before appointment</span>';
      }
      if (!isEmpty(getDashboard?.twoFaModal)) {
        setDashboard2faModalSchema(() => getDashboard.twoFaModal);
        if (store.getState().dashboard.twofaPopup) {
          store.dispatch.modal.toggleOpen('twofaDashboardModal');
          store.dispatch.dashboard.showTwofaPopup(false);
        }
      }
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
      console.log('Error getting dashboard response -> ', error);
    }
  }

  useEffect(() => {
    store.dispatch.addAppointment.clearStore();
    store.dispatch.intelligentScheduler.clearAll();
    store.dispatch.addAppointment.resetSelectedOptions();
    fetchDashboard();
  }, []);

  useEffect(() => {
    return () => {
      const { activateLoader } = props;
      activateLoader({ loading: true });
    };
  }, []);

  const checkIn = data => {
    store.dispatch.notification.close();
    store.dispatch.appointmentCheckIn.storeAppointmentData(data);
    props.history.push('appointments/check_in');
  };
  const checkOut = data => {
    store.dispatch.appointmentCheckOut.storeAppointmentData(data);
    mix.checkOut(data);
    props.history.push('appointments/check_out');
  };
  const startVideoCall = async params => {
    store.dispatch.loader.activateLoader({ loading: true });
    const { practice_id, practice_mgmt, patient_id, appointment_id } = params;
    try {
      const {
        data: { getVideoVisitUrl: visitData },
      } = await props.client.query({
        query: GetVideoVisitUrl,
        variables: { input: { practice_id, practice_mgmt, patient_id, appointment_id } },
      });
      store.dispatch.loader.activateLoader({ loading: false });
      store.dispatch.notification.close();
      const url = get(visitData, 'video_visit.urls.web', '');
      window.open(url, '_blank');
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  };
  const toggleOpenVideoTab = async params => {
    const { canCheckin, labels } = params;
    if (canCheckin) {
      store.dispatch.notification.open({
        data: params,
        labels,
        actions: { firstButtonAction: checkIn, secondButtonAction: startVideoCall },
      });
    } else {
      startVideoCall(params);
    }
  };
  const cancelAppointment = async params => {
    const { practiceId, patientId, appointmentId, providerId, locationId, visitType } = params;
    const { cancellationReason, cancellationReasonValue: cancellationReasonId } = store.getState().radioInput;
    const foundCancellationComments = store
      .getState()
      .formGroup.events.find(event => event.id === 'otherReasonInput' && !event.isInitial);
    const cancellationComments =
      cancellationReason === 'OT' ? foundCancellationComments && foundCancellationComments.value : String();
    store.dispatch.modal.toggleClose();
    store.dispatch.loader.activateLoader({ loading: true });
    const input = {
      practiceId,
      patientId,
      appointmentId,
      cancellationReasonId,
      cancellationComments,
      providerId,
      locationId,
    };
    try {
      store.dispatch.appointments.toggleCancellingAppointment();
      store.dispatch.mixpanel.addNonActionableMetadata({ appointmentCancellationVisitType: visitType });
      const data = await props.client.mutate({ mutation: CancelAppointment, variables: { input } });
      // Display cancellation success message to user
      if (data?.data?.cancelAppointment === 200) {
        store.dispatch.modal.toggleOpen('cancelApptSuccessModal');
        store.dispatch.loader.activateLoader({ loading: false });
        fetchDashboard();
      }
      store.dispatch.radioInput.resetRadioInput();
    } catch (error) {
      console.error(error);
    }
  };
  const rescheduleAppointment = async ({
    prePayment,
    route,
    autoSchedule,
    appointment: {
      metadata: { practice_id: practiceId, patient_id: patientId, practice_mgmt: practiceMgmt },
      payload: {
        provider,
        visit_reason_id: visitReasonId,
        visit_reason: { name: visitType } = {},
        location: {
          id: locationId,
          address: {
            line1: addressLine1 = String(),
            line2: addressLine2 = String(),
            line3: addressLine3 = String(),
            city = String(),
            state_name: state = String(),
            zip_code: zip = String(),
            name: office = String(),
          },
          phones = [],
          guid: locationGuid,
        },
        resource_id: resourceId,
      },
    } = {},
  }) => {
    const { addAppointment } = store.dispatch;
    const primaryPhone = phones.length && phones.find(p => p.is_primary);
    const phone = get(primaryPhone, 'phone_number', String());
    const massagedLocation = {
      locationId,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zip,
      phone,
      office,
      locationGuid,
    };
    addAppointment.selectProvider({
      resourceId,
      practiceId,
      practiceMgmt,
      patientId,
      provider,
      providerId: provider.id,
      selectedProviderId: provider.id,
      providerGuid: provider.guid,
      autoSchedule,
      avatar: { url: provider.photo },
    });
    addAppointment.selectVisitReason({
      prePayment: { amount: prePayment },
      visitReasonId,
      visitType,
    });
    addAppointment.selectLocation({ ...massagedLocation });
    addAppointment.setPrePayment(prePayment);
    addAppointment.setFromReschedule();
    props.history.push(route);
  };
  const showPaymentHistory = _ => {
    document.getElementById('appointmentHistoryTab').style.display = 'none';
    document.getElementById('appointmentHistory').classList.remove('active');

    document.getElementById('visitHistoryTab').style.display = 'none';
    document.getElementById('visitHistory').classList.remove('active');
    document.getElementById('paymentHistoryTab').style.display = 'grid';
    document.getElementById('paymentHistory').classList.add('active');
  };
  const showAppointmentHistory = _ => {
    document.getElementById('paymentHistoryTab').style.display = 'none';
    document.getElementById('paymentHistory').classList.remove('active');

    document.getElementById('visitHistoryTab').style.display = 'none';
    document.getElementById('visitHistory').classList.remove('active');

    document.getElementById('appointmentHistoryTab').style.display = 'grid';
    document.getElementById('appointmentHistory').classList.add('active');
  };
  const showVisitHistory = _ => {
    document.getElementById('paymentHistoryTab').style.display = 'none';
    document.getElementById('paymentHistory').classList.remove('active');

    document.getElementById('appointmentHistoryTab').style.display = 'none';
    document.getElementById('appointmentHistory').classList.remove('active');

    document.getElementById('visitHistoryTab').style.display = 'grid';
    document.getElementById('visitHistory').classList.add('active');
  };
  const messageProvider = data => {
    store.dispatch.messages.selectProvider(data);
    props.history.push(`/messages?provider=${data.provider_id}`);
  };
  const toggleQRCode = display => {
    if (display === 'Android') {
      document.getElementById('checkInOverlayQRCodeiOS').style.display = 'none';
      document.getElementById('QRCodeSwitchBtniOS').classList.remove('active');

      document.getElementById('checkInOverlayQRCodeAndroid').style.display = 'block';
      document.getElementById('QRCodeSwitchBtnAndroid').classList.add('active');
    } else {
      document.getElementById('checkInOverlayQRCodeAndroid').style.display = 'none';
      document.getElementById('QRCodeSwitchBtnAndroid').classList.remove('active');

      document.getElementById('checkInOverlayQRCodeiOS').style.display = 'block';
      document.getElementById('QRCodeSwitchBtniOS').classList.add('active');
    }
  };
  const showToday = _ => {
    document.getElementById('nextAppointmentshowUpcomingwrapper').style.display = 'none';
    document.getElementById('nextAppointmentshowUpcomingbutton').classList.remove('active');

    document.getElementById('nextAppointmentshowTodaywrapper').style.display = 'grid';
    document.getElementById('nextAppointmentshowTodaybutton').classList.add('active');
  };
  const showUpComing = _ => {
    document.getElementById('nextAppointmentshowTodaywrapper').style.display = 'none';
    document.getElementById('nextAppointmentshowTodaybutton').classList.remove('active');

    document.getElementById('nextAppointmentshowUpcomingbutton').classList.add('active');
    document.getElementById('nextAppointmentshowUpcomingwrapper').style.display = 'grid';
    document.getElementById('nextAppointmentshowUpcomingEmptywrapper').style.display = 'flex';
  };
  let currentIndex = 0;
  const showTodayAppointmentAtIndex = (index = 0) => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentTodayIndex-"]');
    appointmentElements.forEach(element => {
      const appointmentElement = element;
      appointmentElement.style.display = 'none';
    });

    if (index >= 0 && index < appointmentElements.length) {
      appointmentElements[index].style.display = 'grid';
      appointmentElements[index].classList.add('showIndex');
    }
    //Next and back navigation
    const getnextButtons = document.querySelectorAll('[id^="nextNavigatebutton-"]');
    const getbackButtons = document.querySelectorAll('[id^="backNavigatebutton-"]');

    if (appointmentElements.length) {
      getnextButtons[index].classList.add('commonStyle');
      getbackButtons[index].classList.add('commonStyle');
    }
    if (appointmentElements.length === 1) {
      getnextButtons[index].classList.add('Disabled');
      getbackButtons[index].classList.add('Disabled');
    } else if (index === 0 && appointmentElements.length > 1) {
      getbackButtons[index].classList.add('Disabled');
    } else if (index === appointmentElements.length - 1 && appointmentElements.length > 1) {
      getnextButtons[index].classList.add('Disabled');
    }
  };
  const Next = _ => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentTodayIndex-"]');
    currentIndex++;
    if (currentIndex >= appointmentElements.length) {
      currentIndex = 0;
    }
    showTodayAppointmentAtIndex(currentIndex);
  };
  const Previous = _ => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentTodayIndex-"]');
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = appointmentElements.length - 1;
    }
    showTodayAppointmentAtIndex(currentIndex);
  };

  const showUpcomingAppointmentAtIndex = (index = 0) => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentUpcomingIndex-"]');
    appointmentElements.forEach(element => {
      const appointmentElement = element;
      appointmentElement.style.display = 'none';
    });

    if (index >= 0 && index < appointmentElements.length) {
      appointmentElements[index].style.display = 'grid';
      appointmentElements[index].classList.add('showIndex');
    }

    //Next and back navigation
    const getnextButtons = document.querySelectorAll('[id^="nextUpcomingNavigatebutton-"]');
    const getbackButtons = document.querySelectorAll('[id^="backUpcomingNavigatebutton-"]');
    if (appointmentElements.length) {
      getnextButtons[index].classList.add('commonStyle');
      getbackButtons[index].classList.add('commonStyle');
    }
    if (appointmentElements.length === 1) {
      getnextButtons[index].classList.add('Disabled');
      getbackButtons[index].classList.add('Disabled');
    } else if (index === 0 && appointmentElements.length > 1) {
      getbackButtons[index].classList.add('Disabled');
    } else if (index === appointmentElements.length - 1 && appointmentElements.length > 1) {
      getnextButtons[index].classList.add('Disabled');
    }
  };
  const UpcomingNext = _ => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentUpcomingIndex-"]');
    currentIndex++;
    if (currentIndex >= appointmentElements.length) {
      currentIndex = 0;
    }
    showUpcomingAppointmentAtIndex(currentIndex);
  };
  const UpcomingPrevious = _ => {
    const appointmentElements = document.querySelectorAll('[id^="nextAppointmentUpcomingIndex-"]');
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = appointmentElements.length - 1;
    }
    showUpcomingAppointmentAtIndex(currentIndex);
  };
  useEffect(() => {
    showTodayAppointmentAtIndex();
    showUpcomingAppointmentAtIndex();
  });

  const goToTwoFASettings = route => {
    store.dispatch.settings.scrollToView('twofaForm');
    props.history.push(route);
  };
  const redirectToSettings = _ => {
    const missingFields = store.getState().appointments?.missingRegistrationFields;
    // eslint-disable-next-line no-restricted-globals
    const personal = missingFields?.personal;
    const additional_demographics = missingFields?.additional_demographics;
    const document = missingFields?.documents;
    const payment = missingFields?.payment;
    store.dispatch.modal.toggleClose('completeRegistrationModal');
    let route = '/profile_settings/personal';
    store.dispatch.appointments.showMissingForms({ param: true });
    if (personal === true) {
      route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === '/profile_settings/personal')?.[0].url;
    } else if (additional_demographics === true) {
      route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === '/profile_settings/demographics')?.[0].url;
    } else if (document === true) {
      route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === '/profile_settings/documents')?.[0].url;
    } else if (payment === true) {
      route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === '/profile_settings/payment')?.[0].url;
    }
    props.history.push(route);
  };
  return (
    <DashboardContext.Provider
      value={{
        checkIn,
        checkOut,
        rescheduleAppointment,
        cancelAppointment,
        showPaymentHistory,
        showAppointmentHistory,
        showVisitHistory,
        toggleOpenVideoTab,
        startVideoCall,
        messageProvider,
        toggleQRCode,
        goToTwoFASettings,
        redirectToSettings,
        showToday,
        showUpComing,
        Next,
        Previous,
        UpcomingNext,
        UpcomingPrevious,
        loadPatientStatement,
      }}
    >
      <div className={styles.dashboardContainer}>
        <SchemaParser schema={[dashboard2faModalSchema]} />
        {dashboardLayout?.map(component => (
          <SchemaParser key={component?.id} schema={[component]} />
        ))}
      </div>
    </DashboardContext.Provider>
  );
};

const mapStateToProps = ({ appointments, loader }) => ({
  appointments,
  loader,
});

const mapDispatchToProps = ({ loader }) => ({
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetDashboard, { name: 'getDashboard' }),
);

export default enhancer(DashboardContainer);
