import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';
import ProfileEmergencyContact from './EmergencyContact';
import ProfileEmploymentInfo from './EmploymentInfo';
import ProfilePhysician from './Physician';
import { checkDateOfBirth } from '../../commonjs';

const transformFormData = formData => {
  const output = {
    ...formData.secondary,
    personalDetails: {
      ...formData.primary.personalDetails,
      ...formData.secondary.personalDetails,
    },
  };

  const cleanObject = function (object) {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === 'object'){
        cleanObject(v);
      }
      if (typeof v !== 'object'){
        if (v === undefined){
          // eslint-disable-next-line
          object[k] = ""
        }
      }
    });
    return object;
  };

  return cleanObject(output);
};

const enableUpdateButton = complete => {
  store.dispatch.button.enableButton({
    btn: 'profileSettingsUpdate',
    complete,
  });
};

const customFields = {
  ProfileEmergencyContact,
  ProfileEmploymentInfo,
  ProfilePhysician,
};

export const Component = React.memo(({ client, formData, schema, submit, uiSchema }) => {
  const [demographicsForm, setDemographicsForm] = useState({ formData });
  const {
    formData: {
      primary: {
        personalDetails: { dateOfBirth },
      },
    },
  } = demographicsForm;

  const isValid = { valid: false };
  if (checkDateOfBirth({ inputDates: dateOfBirth })) {
    isValid.valid = false;
  } else {
    isValid.valid = !Array.isArray(demographicsForm.errors) || demographicsForm.errors.length < 1;
  }
  useEffect(() => {
    store.dispatch.loader.hide();
  }, []);
  const handleChange = () => {
    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
    if(practiceMgmt === 'vertex'){
      enableUpdateButton(false);
    }else
    enableUpdateButton(demographicsForm.edit && isValid.valid);
  }
  const handleSubmit = async () => {
    enableUpdateButton(false);

    try {
      const input = transformFormData(demographicsForm.formData);
      if (typeof input.employmentInfo.employer === 'string')
        input.employmentInfo.employer = {name: '', address: {}}
      const { data } = await client.mutate({
        mutation: Mutations.demographics,
        variables: { 
          input: { ...transformFormData(demographicsForm.formData),
            patientId: store.getState().settings.patient_Id,
          }, 
        },
      });

      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.open(data[Object.keys(data)[0]]);
      store.dispatch.settings.refetch();
    } catch (error) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.error(error?.message);
      enableUpdateButton(true);
    }

    store.dispatch.loader.hide();
  };

  submit ? handleSubmit() : handleChange();
  const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
  const formStyle = {
    pointerEvents: practiceMgmt === 'vertex' ? 'none' : 'auto',
    opacity: practiceMgmt === 'vertex' ? 0.5 : 1,
  };
  return (
    practiceMgmt === 'vertex' ? (
      <div style={formStyle}>
        <Form
          customFields={customFields}
          formData={demographicsForm.formData}
          onChange={setDemographicsForm}
          schema={schema}
          uiSchema={uiSchema}
        />
      </div>
      ) : (
        <Form
          customFields={customFields}
          formData={demographicsForm.formData}
          onChange={setDemographicsForm}
          schema={schema}
          uiSchema={uiSchema}
        />
      )
  );
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);
